import CachedIcon from '@mui/icons-material/Cached';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { SUPPORT_CHANNELS } from 'config';
import styles from './styles.module.scss';

const handleReload = () => {
  window.location.reload();
};

const ApiError = () => {
  return (
    <Box className={styles['api-error']} role="alert">
      <Box className={styles['api-error__content']}>
        <Typography component="h1" variant="h5" mb={2}>
          We are sorry, something went wrong
        </Typography>
        <Typography component="p" variant="body2" paragraph mb={6} className={styles['api-error__message']}>
          Try reloading the page in a few minutes. If the problem keeps recurring, get in touch with support on{' '}
          <Link href={`mailto:${SUPPORT_CHANNELS.email}`} component="a" target="_blank" rel="noopener noreferrer">
            {SUPPORT_CHANNELS.email}
          </Link>
        </Typography>
        <Button
          size="small"
          variant="outlined"
          className={styles['api-error__reload-button']}
          color="error"
          endIcon={<CachedIcon />}
          onClick={handleReload}
        >
          Reload page
        </Button>
      </Box>
    </Box>
  );
};

export default ApiError;
