import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { SUPPORT_CHANNELS } from 'config';
import styles from './styles.module.scss';

const DefaultError = () => {
  return (
    <Box className={styles['default-error']} role="alert">
      <Box className={styles['default-error__content']}>
        <Typography variant="h3">We are sorry, something went wrong</Typography>
        <Typography component="p" variant="body2" paragraph>
          The URL might have been changed, some of the data might have failed to load or you might not have access to
          this service yet.
        </Typography>
        <Typography component="p" variant="body2" paragraph>
          Check the URL, refresh the page or try again in 30 minutes. If the problem keeps returning, send a message to:
          <br />
          <br />
          <strong>EMAIL</strong>
          <br />
          <Link href={`mailto:${SUPPORT_CHANNELS.email}`} component="a" target="_blank" rel="noopener noreferrer">
            {SUPPORT_CHANNELS.email}
          </Link>
          <br />
          <br />
          <strong>SLACK</strong>
          <br />
          <Link href={SUPPORT_CHANNELS.slack.channel_url} component="a" target="_blank" rel="noopener noreferrer">
            {SUPPORT_CHANNELS.slack.channel_name}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default DefaultError;
