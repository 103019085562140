import { combineReducers } from 'redux';
import accountsReducer from 'store/slices/accountsSlice';
import awsDataReducer from 'store/slices/awsDataSlice';
import controlGroupsReducer from 'store/slices/controlGroupsSlice';
import groupsReducer from 'store/slices/groupsSlice';
import permissionSetsReducer from 'store/slices/permissionSetsSlice';
import userProfileGroupsReducer from 'store/slices/userProfileGroupsSlice';
import userProfileReducer from 'store/slices/userProfileSlice';
import usersReducer from 'store/slices/usersSlice';

const rootReducer = combineReducers({
  accounts: accountsReducer,
  awsData: awsDataReducer,
  groups: groupsReducer,
  permissionSets: permissionSetsReducer,
  users: usersReducer,
  userProfile: userProfileReducer,
  userProfileGroups: userProfileGroupsReducer,
  controlGroups: controlGroupsReducer,
});

export default rootReducer;
