import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';

export interface ControlGroups {
  developer?: boolean;
}

const initialState: ControlGroups = {
  developer: false,
};

export const controlGroupSlice = createSlice({
  name: 'controlGroups',
  initialState,
  reducers: {
    setControlGroups: (state, action) => {
      return {
        developer: action.payload.developer,
      };
    },
  },
});

export const selectControlGroups = (state: RootState): ControlGroups => state.controlGroups;

export const { setControlGroups } = controlGroupSlice.actions;
export default controlGroupSlice.reducer;
