import React, { useEffect } from 'react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import InlineLoading from 'components/inline-loading/InlineLoading';
import useAuth from 'hooks/useAuth';
import { Outlet } from 'react-router-dom';

export const RequiredAuth: React.FC = () => {
  const { oktaAuth, authState } = useAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return <InlineLoading />;
  }

  return <Outlet />;
};
