import axios, { AxiosInstance, AxiosRequestConfig, CancelToken } from 'axios';
import { API_URL } from 'config';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common.Pragma = 'no-cache'; // IE 11
axios.defaults.headers.common['Content-Type'] = 'application/json';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const injectInterceptors = (client: AxiosInstance, interceptors: any = {}) => {
  const { response, request } = interceptors;
  const newClient = { ...client };

  if (response) {
    client.interceptors.response.use(response);
  }

  if (request) {
    client.interceptors.request.use(request);
  }

  return newClient;
};

const axiosHandleUnAuthRequests = (client: AxiosInstance) => {
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      const unAuthorizedResponses = [401, 403];
      const { response: { status } = { status: undefined } } = error;

      if (unAuthorizedResponses.includes(status)) {
        window.location.reload();
      }

      return Promise.reject(error);
    }
  );

  return client;
};

export type CreateAxiosClientArgs0 = { config?: AxiosRequestConfig; interceptors?: unknown };

export const createAxiosClient = (settings: CreateAxiosClientArgs0 = {}) => {
  const { config = {}, interceptors } = settings;

  const axiosInstance = axios.create(config);

  // Converter switches between snakeCase and camelCase
  const client: AxiosInstance = axiosInstance;

  injectInterceptors(client, interceptors);

  axiosHandleUnAuthRequests(client);

  return client;
};

export const useAuthAxiosClient = (authToken: string | undefined, settings = {}) => {
  const defaultSettings = { config: { headers: { Authorization: `Bearer ${authToken}` }, baseURL: API_URL } };
  const client = createAxiosClient({ ...defaultSettings, ...settings });
  return client;
};

type GetArgs = {
  url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any;
  cancelToken?: CancelToken;
  client: AxiosInstance;
};

export const get = ({ url, params, cancelToken, client }: GetArgs) =>
  client.get(url, {
    cancelToken,
    params,
  });

export const basicSettings = { config: { baseURL: API_URL } };
export const basicClient = createAxiosClient(basicSettings);
