import { createContext, useCallback, useEffect, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import Error from 'components/error/Error';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { AuthProvider } from 'hooks/useAuth';
import { createOktaAuth } from 'hooks/useAuth';
import NavBar from 'layouts/navbar-layout/NavBar';
import { useAuthAxiosClient } from 'lib/axios';
import { datadogConfig } from 'lib/datadog';
import { oktaAuthConfig } from 'lib/okta/oidc-config';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import Routing from 'routes/Routing';
import { ControlGroups } from 'store/slices/controlGroupsSlice';
import { fetchUserProfileGroups, userProfileGroupsSelector } from 'store/slices/userProfileGroupsSlice';
import { setUserProfile } from 'store/slices/userProfileSlice';
import theme from 'themes/mainTheme';
import { AppUserClaims } from 'types/oktaOverrideTypes';
import styles from './style.module.scss';

const nonce = process.env.WEBPACK_NONCE;
const cache = createCache({
  key: 'css',
  prepend: true,
  nonce,
});

type UserProfileExtended = Pick<AppUserClaims, 'locale' | 'preferred_username' | 'groups' | 'email' | 'name'>;

const initUserContext = {
  email: '',
  groups: [''],
  locale: '',
  name: '',
  preferred_username: '',
} as UserProfileExtended;

export const UserContext = createContext(initUserContext);

const initControlGroupContext: ControlGroups = {
  developer: false,
};

export const ControlGroupContext = createContext(initControlGroupContext);

datadogRum.init(datadogConfig);
datadogRum.startSessionReplayRecording();

export const oktaAuth = createOktaAuth(oktaAuthConfig);

export const App = () => {
  const dispatch = useAppDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(false);
  const [userProfileExtended, setUserProfileExtended] = useState({});
  const [controlGroups, setControlGroups] = useState<ControlGroups>({});

  const navigate = useNavigate();

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = useCallback(async (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    // console.log(previousAuthState);
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      await triggerLogin();
    } else {
      triggerLogin();
    }
  };

  useEffect(() => {
    const authState = async () => {
      await oktaAuth.isAuthenticated().then((response) => {
        setIsAuthenticated(response);
      });
    };
    authState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { userProfileGroups }: { userProfileGroups: any } = useAppSelector(userProfileGroupsSelector);

  const authToken = oktaAuth.getAccessToken();
  const client = useAuthAxiosClient(authToken);

  useEffect(() => {
    if (isAuthenticated) {
      oktaAuth
        .getUser()
        .then((userInfo: UserProfileExtended) => {
          dispatch(
            setUserProfile({
              type: 'userProfile/userProfileSet',
              payload: userInfo,
            })
          );

          dispatch(fetchUserProfileGroups(client, userInfo?.preferred_username));
          setUserProfileExtended({
            email: userInfo.email,
            groups: userProfileGroups,
            locale: userInfo.locale,
            name: userInfo.name,
            preferred_username: userInfo.preferred_username,
          });

          setControlGroups({
            developer: userInfo?.groups?.includes(oktaAuthConfig.controlGroups.developer),
          });

          datadogRum.setUser({
            name: userInfo.name,
            email: userInfo.email,
          });
        })
        .catch((err) => {
          triggerLogin();
          console.warn(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, dispatch]);

  return (
    <div className="App">
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={customAuthHandler}>
        <ErrorBoundary FallbackComponent={({ error }: any) => <Error statusCode={error.statusCode} />}>
          <AuthProvider>
            <UserContext.Provider value={userProfileExtended}>
              <ControlGroupContext.Provider value={controlGroups}>
                <CacheProvider value={cache}>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Box className={styles.wrapper}>
                      <NavBar />
                      <div className={styles['wrapper-right']}>
                        <div id="main-content" className={styles.main}>
                          <Routing />
                        </div>
                      </div>
                    </Box>
                  </ThemeProvider>
                </CacheProvider>
              </ControlGroupContext.Provider>
            </UserContext.Provider>
          </AuthProvider>
        </ErrorBoundary>
      </Security>
    </div>
  );
};

export default App;
