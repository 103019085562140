import { StorageType } from '@okta/okta-auth-js';
import { CONTROL_GROUP_DEV, OKTA_CLIENT_ID, OKTA_ISSUER } from 'config';

// Configure TokenManager to use sessionStorage instead of localStorage
const oktaStorageConfig: StorageType = 'sessionStorage';

export const oktaAuthConfig = {
  clientId: OKTA_CLIENT_ID,
  issuer: OKTA_ISSUER,
  pkce: true,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'profile', 'email', 'offline_access', 'groups'],
  services: {
    autoRenew: true, // Attempt refresh in background before token expires
    autoRemove: true, // Remove stale tokens that cannot be refreshed
    syncStorage: true, // Keep token storage synced between browser tabs
  },
  storageManager: {
    token: {
      storageType: oktaStorageConfig,
    },
    cache: {
      storageType: oktaStorageConfig,
    },
    transaction: {
      storageType: oktaStorageConfig,
    },
  },
  tokenManager: {
    storage: oktaStorageConfig,
  },
  controlGroups: {
    developer: CONTROL_GROUP_DEV,
  },
};
