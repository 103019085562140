import { createTheme } from '@mui/material/styles';
import { SpacingOptions } from '@mui/system';
import OpenSansBoldWoff from 'assets/fonts/open-sans-v27-latin-600.woff';
import OpenSansBoldWoff2 from 'assets/fonts/open-sans-v27-latin-600.woff2';
import OpenSansItalicWoff from 'assets/fonts/open-sans-v27-latin-italic.woff';
import OpenSansItalicWoff2 from 'assets/fonts/open-sans-v27-latin-italic.woff2';
import OpenSansRegularWoff from 'assets/fonts/open-sans-v27-latin-regular.woff';
import OpenSansRegularWoff2 from 'assets/fonts/open-sans-v27-latin-regular.woff2';
import breakpoints from 'styles/breakpoints.module.scss';
import palette from 'styles/palette.module.scss';
import spacing from 'styles/spacing.module.scss';
import typography from 'styles/typography.module.scss';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    ['contained-white']: true;
    ['field-text']: true;
  }
}

type typographyTransform = 'none' | 'capitalize' | 'uppercase' | 'lowercase';

// A custom theme for this app
const theme = createTheme({
  palette: {
    background: {
      default: palette.defaultBackground,
      paper: palette.backgroundPaper,
    },
    text: {
      primary: palette.primaryText,
      secondary: palette.secondaryText,
      disabled: palette.disabledText,
    },
    primary: {
      main: palette.primaryMain,
      light: palette.primaryLight8p,
      dark: palette.primaryDark,
      contrastText: palette.primaryContrastText,
    },
    secondary: {
      main: palette.secondaryMain,
    },
    error: {
      main: palette.errorMain,
      dark: palette.errorDark,
    },
    warning: {
      main: palette.warningMain,
      dark: palette.warningDark,
    },
    success: {
      main: palette.successMain,
      dark: palette.successDark,
    },
    action: {
      hover: palette.actionHover,
      active: palette.actionActive,
    },

    // ... loads more theming options here
  },
  typography: {
    fontFamily: [`"Open Sans"`, 'Arial', 'Helvetica', 'sans-serif'].join(','),
    button: {
      textTransform: typography.typographyButtonTextTransform as typographyTransform,
      fontWeight: typography.typographyButtonWeight,
      fontSize: parseInt(spacing.base) * 2,
    },
    h2: {
      fontWeight: typography.typographyH2Weight,
      fontSize: parseInt(spacing.base) * 4,
    },
    h3: {
      fontWeight: typography.typographyH6Weight,
      fontSize: parseInt(spacing.base) * 3,
      marginBottom: parseInt(spacing.base) * 2,
    },
    h4: {
      fontWeight: typography.typographyH6Weight,
      fontSize: parseInt(spacing.base) * 2.5,
      marginBottom: parseInt(spacing.base) * 2,
    },
    h5: {
      fontWeight: typography.typographyH5Weight,
      fontSize: parseInt(spacing.base) * 2.5,
    },
    h6: {
      fontWeight: typography.typographyH6Weight,
    },
    body1: {
      fontWeight: typography.typographyBody1Weight,
      textDecorationThickness: typography.typographyBody1TextDecorationThickness,
      overflowWrap: 'break-word',
    },
    subtitle1: {
      fontWeight: typography.typographySubtitle1Weight,
    },
    subtitle2: {
      fontWeight: typography.typographySubtitleWeight,
    },
    caption: {
      display: typography.typographyCaptionDisplay,
      fontSize: parseInt(spacing.base) * 1.75,
      color: palette.secondaryTextLight,
    },
  },
  spacing: parseInt(spacing.base) as unknown as SpacingOptions,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
            font-weight: 400;
            font-family: 'Open Sans';
            font-style: normal;
            font-display: swap;
            src: local('Open Sans'), url(${OpenSansRegularWoff2}) format('woff2'),
            url(${OpenSansRegularWoff}) format('woff');
        }

        @font-face {
            font-weight: 600;
            font-family: 'Open Sans';
            font-style: normal;
            font-display: swap;
            src: local('Open Sans'), url(${OpenSansBoldWoff2}) format('woff2'),
            url(${OpenSansBoldWoff}) format('woff');
        }

        @font-face {
            font-weight: 400;
            font-family: 'Open Sans';
            font-style: italic;
            font-display: swap;
            src: local('Open Sans'), url(${OpenSansItalicWoff2}) format('woff2'),
            url(${OpenSansItalicWoff}) format('woff');
        }
      `,
    },
    MuiButton: {
      variants: [
        {
          props: {
            variant: 'contained-white',
          },
          style: {
            fontWeight: 700,
            backgroundColor: palette.backgroundPaper,
            color: palette.primaryMain,
            '&:hover': {
              backgroundColor: palette.primaryLight4p,
            },
          },
        },
        {
          props: {
            variant: 'field-text',
          },
          style: {
            padding: 0,
            fontSize: '0.875rem',
            lineHeight: 1.5,
            minWidth: 'initial',
            verticalAlign: 'top',
            '&:hover': {
              background: 'none',
            },
          },
        },
        {
          props: {
            variant: 'text',
          },
          style: {
            textDecoration: 'underline',
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontWeight: 600,
          lineHeight: 1.5,
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: {
            color: 'primary',
          },
          style: {
            fontWeight: 700,
            fontSize: '0.875rem',
            backgroundColor: palette.snackbarBackground,
            color: palette.primaryContrastText,
          },
        },
        {
          props: {
            color: 'default',
          },
          style: {
            fontWeight: 700,
            fontSize: '0.875rem',
            backgroundColor: palette.defaultBackground,
            color: palette.primaryText,
          },
        },
        {
          props: {
            color: 'warning',
          },
          style: {
            fontWeight: 700,
            fontSize: '0.875rem',
            backgroundColor: palette.warningShades12p,
            color: palette.warningDark,
          },
        },
        {
          props: {
            color: 'error',
          },
          style: {
            fontWeight: 700,
            fontSize: '0.875rem',
            backgroundColor: palette.errorShades12p,
            color: palette.errorDark,
          },
        },
        {
          props: {
            color: 'secondary',
          },
          style: {
            fontWeight: 700,
            fontSize: '0.875rem',
            backgroundColor: palette.primaryTextLight12p, // shouldn't use text tokens for backgrounds
            color: palette.primaryText,
          },
        },
        {
          props: {
            color: 'info',
          },
          style: {
            fontWeight: 700,
            fontSize: '0.875rem',
            backgroundColor: palette.primaryMain,
            color: palette.primaryContrastText,
          },
        },
      ],
    },
    MuiAlert: {
      variants: [
        {
          props: {
            color: 'info',
          },
          style: {
            '& .MuiAlert-icon': {
              color: palette.alphaBannerIcon,
            },
          },
        },
      ],
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: parseInt(breakpoints.breakpoint_xs),
      sm: parseInt(breakpoints.breakpoint_sm),
      md: parseInt(breakpoints.breakpoint_md),
      lg: parseInt(breakpoints.breakpoint_lg),
      xl: parseInt(breakpoints.breakpoint_xl),
    },
  },
});

export default theme;
