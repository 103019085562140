import { createContext, ReactNode, useCallback, useContext } from 'react';
import {
  AccessToken,
  AuthState,
  CustomUserClaims,
  OktaAuth,
  OktaAuthOptions,
  RefreshToken,
  SignoutOptions,
  UserClaims,
} from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

type LoginProps = { loginHint?: string };

type User = {
  name: string;
};

type ExtendedAuthState = (AuthState & (AuthState & { user: User | null })) | null;

export type Auth = {
  authState: ExtendedAuthState;
  setOriginalUri: (originalUri: string, state?: string | undefined) => void;
  initializeLogin: (props?: LoginProps) => Promise<void>;
  logout: () => Promise<void>;
  login: (accessToken: AccessToken | undefined, refreshToken: RefreshToken | undefined) => Promise<void>;
  oktaAuth: OktaAuth;
  getUser?: () => Promise<UserClaims<CustomUserClaims>>;
};

export const AuthContext = createContext<Auth | undefined>(undefined);

export const useAuthRaw = () => {
  const auth = useOktaAuth();

  const authState = auth.authState as ExtendedAuthState;
  const oktaAuth = auth.oktaAuth;

  const getUser = async () => oktaAuth.getUser();

  const setOriginalUri = useCallback(
    (originalUri: string, state?: string | undefined) => {
      oktaAuth.setOriginalUri(originalUri, state);
    },
    [oktaAuth]
  );

  const login = useCallback(
    async (accessToken: AccessToken | undefined, refreshToken: RefreshToken | undefined) => {
      try {
        if (!accessToken || !refreshToken) {
          throw Error('No tokens provided');
        }
        await oktaAuth.signInWithRedirect();
      } catch (e) {
        setOriginalUri(location.pathname);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOriginalUri, location.pathname]
  );

  const initializeLogin = async ({ loginHint }: LoginProps = {}) => oktaAuth.signInWithRedirect({ loginHint });

  const logout = async () => {
    try {
      // remove session cookie (cleanup)
      await oktaAuth.signOut('/' as SignoutOptions); // then logout user so that cookie is removed (cleanup)
    } catch (e) {
      // if this fails, it doesn't really matter because the old auth token
      // will be revoked after Okta sign out, and the cookie replaced during next login
    } finally {
      await oktaAuth.signOut();
    }
  };

  return { getUser, initializeLogin, login, logout, authState, oktaAuth, setOriginalUri };
};

export const createOktaAuth = (args: OktaAuthOptions) => {
  return new OktaAuth(args);
};

interface AuthProviderParams {
  children: ReactNode;
  authHook?: () => Auth;
}

/**
 * AuthProvider saves the useAuth hook in context for use across the application.
 * It optionally accepts an authHook prop, which allows you to override the auth hook,
 * which is particularly useful for testing.
 */
export function AuthProvider({ children, authHook = useAuthRaw }: AuthProviderParams) {
  const auth = authHook();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

/**
 * Hook for child components to get the Auth object and re-render when it
 * changes.
 */
export default function useAuth() {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('Auth context is missing');
  }

  return authContext;
}
