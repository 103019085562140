import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  hasErrors: false,
  accounts: [],
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    getAccounts: (state) => {
      state.loading = true;
    },
    getAccountsSuccess: (state, { payload }) => {
      state.accounts = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getAccountsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { getAccounts, getAccountsSuccess, getAccountsFailure } = accountsSlice.actions;

// The reducer
export const accountsReducer = accountsSlice.reducer;
export default accountsReducer;

// A selector
export const accountsSelector = (state) => state.accounts;

// Asynchronous thunk action
export function fetchAccounts(client) {
  return async (dispatch) => {
    dispatch(getAccounts());
    try {
      const response = await client.get('/accounts');
      const data = await response.data;
      dispatch(getAccountsSuccess(data));
    } catch (error) {
      dispatch(getAccountsFailure());
    }
  };
}
