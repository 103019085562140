import React, { useContext, useMemo, useState } from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import Logout from '@mui/icons-material/Logout';
import MarkChatUnread from '@mui/icons-material/MarkChatUnread';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NotificationsActive from '@mui/icons-material/NotificationsActive';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import styled from '@mui/material/styles/styled';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ControlGroupContext, UserContext } from 'App';
import { ReactComponent as AwsLogo } from 'assets/images/aws.svg';
import { EXTERNAL_DOCS_URL } from 'config';
import useAuth from 'hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(openedMixin(theme) as any),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(closedMixin(theme) as any),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const SideList = ({ open, setOpen }) => {
  const [selectedLink] = useState('');
  const currentUser = useContext(UserContext);
  const controlGroups = useContext(ControlGroupContext);
  const { oktaAuth } = useAuth();

  const list = useMemo(
    () => [
      {
        title: 'AWS',
        icon: <AwsLogo className={styles['aws__icon']} />,
        link: '/aws',
        disabled: false,
      },
      {
        title: 'Requests',
        icon: <NotificationsActive />,
        link: 'requests',
        disabled: true,
      },
      {
        title: 'Messages',
        icon: <MarkChatUnread />,
        link: 'messages',
        disabled: true,
      },
      {
        title: 'User Guide',
        icon: <MenuBookIcon />,
        link: EXTERNAL_DOCS_URL,
        target: '_blank',
        disabled: false,
        external: true,
      },
      ...(controlGroups.developer
        ? [
            {
              title: 'Dev',
              icon: <DesignServicesIcon />,
              link: '/dev',
              disabled: false,
            },
          ]
        : []),
    ],
    [controlGroups.developer]
  );

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await oktaAuth.signOut();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeft />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {list.map((item) => (
            <Tooltip key={item.title} title={item.title} enterNextDelay={1000}>
              <ListItem key={item.title} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  // nosemgrep
                  onClick={() => (item.external ? window.open(item.link, '_blank') : navigate(item.link))}
                  selected={selectedLink === item.link}
                  aria-label={`button-${item.title}`}
                  disabled={item.disabled}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ))}
        </List>
        <Divider />
        <Box sx={{ mx: 'auto', mt: 3, mb: 1 }}>
          <Tooltip title={currentUser?.name || ''}>
            <Avatar {...(open && { sx: { width: 100, height: 100 } })} onClick={() => navigate('/profile')} />
          </Tooltip>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          {open && <Typography>{currentUser?.name}</Typography>}
          <Tooltip title="Logout" sx={{ mt: 1 }}>
            <IconButton onClick={handleLogout}>
              <Logout />
            </IconButton>
          </Tooltip>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 0.1 }}>
        <DrawerHeader />
        {list.map((item) => (
          <Link key={item.title} to={item.link} target={item.target} />
        ))}
      </Box>
    </>
  );
};

export default SideList;
