import React, { useState } from 'react';
import { Home, Menu } from '@mui/icons-material';
import MuiAppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import doraLogo from 'assets/images/dora.png';
import { APP_BUILD, APP_VERSION } from 'config';
import { useNavigate } from 'react-router-dom';
import SideList from '../sidelist-layout/Sidelist';

const drawerWidth = 240;

interface AppBar {
  open: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBar>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function NavBar() {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Tooltip title="Menu">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <Menu />
            </IconButton>
          </Tooltip>
          <Tooltip title="Go back to home page">
            <IconButton sx={{ mr: 1 }} onClick={() => navigate('/')}>
              <Home />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Permission Explorer
          </Typography>
          <Tooltip title={`version: ${APP_VERSION}\nbuild: ${APP_BUILD}`}>
            <Avatar src={doraLogo} variant="square" />
          </Tooltip>
        </Toolbar>
      </AppBar>
      <SideList {...{ open, setOpen }} />
    </Box>
  );
}
