import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  hasErrors: false,
  permissionSets: [],
};

const permissionSetsSlice = createSlice({
  name: 'permissionSets',
  initialState,
  reducers: {
    getPermissionSets: (state) => {
      state.loading = true;
    },
    getPermissionSetsSuccess: (state, { payload }) => {
      state.permissionSets = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getPermissionSetsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { getPermissionSets, getPermissionSetsSuccess, getPermissionSetsFailure } = permissionSetsSlice.actions;

// The reducer
export const permissionSetsReducer = permissionSetsSlice.reducer;
export default permissionSetsReducer;

// A selector
export const permissionSetsSelector = (state) => state.permissionSets;

// Asynchronous thunk action
export function fetchPermissionSets(client) {
  return async (dispatch) => {
    dispatch(getPermissionSets());
    try {
      const response = await client.get('/permissionsets');
      const data = await response.data;
      dispatch(getPermissionSetsSuccess(data));
    } catch (error) {
      dispatch(getPermissionSetsFailure());
    }
  };
}
