import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  hasErrors: false,
  userProfileGroups: [''],
};

const userProfileGroupsSlice = createSlice({
  name: 'userProfileGroups',
  initialState,
  reducers: {
    getUserProfileGroups: (state) => {
      state.loading = true;
    },
    getUserProfileGroupsSuccess: (state, { payload }) => {
      state.userProfileGroups = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getUserProfileGroupsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { getUserProfileGroups, getUserProfileGroupsSuccess, getUserProfileGroupsFailure } =
  userProfileGroupsSlice.actions;

// A selector
export const userProfileGroupsSelector = (state) => state.userProfileGroups;

// The reducer
export const userProfileGroupsReducer = userProfileGroupsSlice.reducer;
export default userProfileGroupsReducer;

// Async
export function fetchUserProfileGroups(client, userName) {
  return async (dispatch) => {
    dispatch(getUserProfileGroups());
    try {
      const response = await client.get('/users/' + userName);
      const data = await response.data;
      const groupMembership = data[0]['group_membership'].sort();
      dispatch(getUserProfileGroupsSuccess(groupMembership));
    } catch (error) {
      dispatch(getUserProfileGroupsFailure());
    }
  };
}
