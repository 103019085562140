import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  hasErrors: false,
  groups: [],
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    getGroups: (state) => {
      state.loading = true;
    },
    getGroupsSuccess: (state, { payload }) => {
      state.groups = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getGroupsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { getGroups, getGroupsSuccess, getGroupsFailure } = groupsSlice.actions;

// The reducer
export const groupsReducer = groupsSlice.reducer;
export default groupsReducer;

// A selector
export const groupsSelector = (state) => state.groups;

// Asynchronous
export function fetchGroups(client) {
  return async (dispatch) => {
    dispatch(getGroups());
    try {
      const response = await client.get('/groups');
      const data = await response.data;
      dispatch(getGroupsSuccess(data));
    } catch (error) {
      dispatch(getGroupsFailure());
    }
  };
}
