import ApiError from './components/api-error/ApiError';
import DefaultError from './components/default-error/DefaultError';

interface ErrorProps {
  statusCode?: number;
}

const getErrorComponentByCode = (statusCode: number | undefined) => {
  switch (statusCode) {
    case 404:
    case 429:
    case 500:
    case 504:
      return <ApiError />;
    default:
      return <DefaultError />;
  }
};

const Error = (errorOption: ErrorProps) => {
  return getErrorComponentByCode(errorOption.statusCode);
};

export default Error;
