import Box from '@mui/material/Box';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

import classnames from 'classnames';
import styles from './styles.module.scss';

interface InlineLoadingProps extends CircularProgressProps {
  position?: 'left';
}

const InlineLoading = ({ size = 32, position, ...props }: InlineLoadingProps) => {
  return (
    <Box className={classnames(styles['inline-loading'], { [styles['inline-loading--left']]: position === 'left' })}>
      <CircularProgress size={size} {...props} />
    </Box>
  );
};

export default InlineLoading;
