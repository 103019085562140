import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  hasErrors: false,
  awsData: [],
};

const awsDataSlice = createSlice({
  name: 'awsData',
  initialState,
  reducers: {
    getAwsData: (state) => {
      state.loading = true;
    },
    getAwsDataSuccess: (state, { payload }) => {
      state.awsData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getAwsDataFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { getAwsData, getAwsDataSuccess, getAwsDataFailure } = awsDataSlice.actions;

// The reducer
export const awsDataReducer = awsDataSlice.reducer;
export default awsDataReducer;

// A selector
export const awsDataSelector = (state) => state.awsData;

// Asynchronous thunk action
export function fetchAwsData(client) {
  return async (dispatch) => {
    dispatch(getAwsData());
    try {
      const response = await client.get('/combined-data');
      const data = await response.data;
      dispatch(getAwsDataSuccess(data));
    } catch (error) {
      dispatch(getAwsDataFailure());
    }
  };
}
