import { createSelector, createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  hasErrors: false,
  users: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsers: (state) => {
      state.loading = true;
    },
    getUsersSuccess: (state, { payload }) => {
      state.users = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getUsersFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { getUsers, getUsersSuccess, getUsersFailure } = usersSlice.actions;

// A selector
export const usersSelector = (state) => state.users;

// The reducer
export const usersReducers = usersSlice.reducer;
export default usersReducers;

// Async
export function fetchUsers(client) {
  return async (dispatch) => {
    dispatch(getUsers());
    try {
      const response = await client.get('/users');
      const data = await response.data;
      dispatch(getUsersSuccess(data));
    } catch (error) {
      dispatch(getUsersFailure());
    }
  };
}

export const filteredUsersSelector = createSelector(
  (state) => state.users.users,
  (state) => state.users.search,
  (users, search) => {
    return users.filter((user) => {
      return user.includes(search);
    });
  }
);
