import { DefaultPrivacyLevel } from '@datadog/browser-rum';
import {
  APP_VERSION,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_SESSION_SAMPLE_RATE,
  DATADOG_SITE,
  ENVIRONMENT,
  SERVICE,
} from 'config';

export const datadogConfig = {
  applicationId: DATADOG_APPLICATION_ID,
  clientToken: DATADOG_CLIENT_TOKEN,
  site: DATADOG_SITE,
  service: SERVICE,
  env: ENVIRONMENT,
  version: APP_VERSION,
  sampleRate: DATADOG_SESSION_SAMPLE_RATE,
  sessionReplaySampleRate: DATADOG_SESSION_SAMPLE_RATE,
  trackResources: true,
  trackLongTasks: true,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input' as DefaultPrivacyLevel,
};
